import React, { useEffect, useRef, useState } from 'react';
import NavBar from './navBar';
import { ToastContainer } from 'react-toastify';
import { BASE_URL, PLAFORM_BACKEND_BASE_URL } from '../constants/config';
import axios from 'axios';
import toastDisplay from '../constants/toastNotification';

const ChatBot = () => {
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [ipAddress, setIpAddress] = useState(null);
    const [nextQstSuggestion, setNextQstSuggestion] = useState(["What is TradeReboot?","What services does TradeReboot provide?","How do I sign up for an account on TradeReboot?"])

    let chatViewRef = useRef()


    const fetchIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            setIpAddress(data?.ip || null);
        } catch (error) {
            console.error('Error retrieving IP address:', error);
        }
    };

    useEffect(() => {
        fetchIpAddress();
    }, []);

    useEffect(() => {
        if (messages.length) {
            chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight;
        }
    }, [messages])

    const handleSubmit = (e, inputTxt) => {
        if(e?.preventDefault){
            e.preventDefault();
        }
        if(!ipAddress){
            return toastDisplay("User identification failed", "error")
        }
        let tempInputTxt = inputTxt || inputText
        if (tempInputTxt.trim() !== '') {
            setShowLoader(true)
            let tempNewMessages = messages
            tempNewMessages.push({
                text: tempInputTxt,
                sender: 'user',
                timestamp: new Date().toLocaleString(),
            })
            // axios.post("https://e9xnnlxr47.execute-api.ap-south-1.amazonaws.com/tradegpt/query/", {
            // axios.post("http://43.205.120.220:8186/tradegpt/query/", {
            //     "query": tempInputTxt.trim(),
            //     "convID": new Date().getTime().toString()
            // }).then(result => {
            //     console.log("chatWithBottttttttttttttttttt", result);
            //     setShowLoader(false)
            //     tempNewMessages.push({
            //         text: result?.data?.content || "Something went wrong",
            //         sender: 'bot',
            //         timestamp: new Date().toLocaleString()
            //     })
            //     setInputText('');
            // }).catch(err => {
            //     setShowLoader(false)
            //     toastDisplay("Something went wrong", "error")
            // });

            axios.post(PLAFORM_BACKEND_BASE_URL + "/chatWithBot", { qst: tempInputTxt.trim(), ipAddress }).
                then(result => {
                    // console.log("chatWithBottttttttttttttttttt", result);
                    setShowLoader(false)
                    tempNewMessages.push({
                        text: result?.data?.message?.ans || "Something went wrong",
                        sender: 'bot',
                        timestamp: new Date().toLocaleString()
                    })
                    setNextQstSuggestion([...result?.data?.message?.nextQstSuggestions])
                    setMessages([...tempNewMessages]);
                    setInputText('');
                }).catch(err => {
                    setShowLoader(false)
                    toastDisplay("Something went wrong", "error")
                });
            // Call chatbot API or perform chatbot logic here
            // You can add the chatbot's response to the messages array
        }
    };

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            {showLoader && (
                <div className="loading-overlay">
                    <span>
                        <img className="" src={'assets/images/loader.gif'} alt="description" />
                    </span>
                </div>
            )}
            <NavBar activeComponent={'content'} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="font-size-18 my-3 ">Trade GPT</h1>
                    </div>
                </div>
                <div
                    ref={chatViewRef}
                    style={{ height: '23rem', overflow: 'auto' }}
                    className="row">
                    <div className="col">
                        <ul className="list-group chat-list">
                            {messages.map((message, index) => (
                                // <div >
                                <li
                                    key={index}
                                    className={`list-group-item chat-item`}
                                >
                                    <span className={`timestamp ${message.sender === "user" ? 'text-primary' : 'text-success'}  font-wt-600`}>{`${message.sender} `}
                                    <label>{message.timestamp}</label></span>
                                    <pre
                                    className='font-size-15 pt-2'
                                    style={{whiteSpace: 'pre-wrap', overflowX: 'hidden'}}
                                    >{message.text}</pre>
                                </li>
                                // </div>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='' >
                    <div>
                        {nextQstSuggestion.map((i, j) => {
                            return <label
                            onClick={()=>handleSubmit(null, i)}
                            style={{backgroundColor: '#B0E0E6', borderRadius: 20}}
                            className='font-size-15 text-black mx-1 px-3 cursor mt-4 text-align-center' >{i}</label>
                        })}
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col">
                        <form onSubmit={handleSubmit} className='d-flex'>
                            <div className="form-group w-95">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type your message..."
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                />
                            </div>
                            <button
                                style={{ marginLeft: 'auto' }}
                                type="submit" className="btn btn-primary">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatBot;
