import logo from './logo.svg';
import './App.css';
import { useRoutes } from "react-router-dom";
import HomeComponent from './components/HomeComponent';
import Content from './components/Content';
import ChatBot from './components/ChatBot';

const routes = [
  {
    path: "/",
    element: <ChatBot />,
  },
  {
    path: "/content",
    element: <Content />,
  },
  {
    path: "/chatbot",
    element: <ChatBot />,
  }
];

function App() {
  const element = useRoutes (routes);
  return <>{element}</>;
}

export default App;
