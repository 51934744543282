// const env = "local"
const env = "dev"
// const env = "prod"

function get_base_url() {
    if (env === "local") {
        return "http://127.0.0.1:8000"
    }
    else if (env === "dev") {
        return "https://serverai.tradereboot.com"
    }
    else {
        return "https://serverai.tradereboot.com"
    }
}

function get_platform_backend_url() {
    if (env === "local") {
        return "http://127.0.0.1:3212"
    }
    else if (env === "dev") {
        return "https://devserver.tradereboot.com"
    }
    else {
        return "https://server.tradereboot.com"
    }
}

export const BASE_URL = get_base_url()
export const PLAFORM_BACKEND_BASE_URL = get_platform_backend_url()
