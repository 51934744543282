import React, { useEffect, useState } from 'react';
import NavBar from './navBar';
import axios from 'axios';
import { BASE_URL } from '../constants/config';
import toastDisplay from '../constants/toastNotification';
import { ToastContainer } from 'react-toastify';

const Content = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        setShowLoader(true)
        axios.post(BASE_URL + "/file-list", {}).
            then(result => {
                setShowLoader(false)
                // console.log("resulttttttttttttttttttttt", result);
                setFileList(result.data)
                setShowLoader(false)
            }).catch(err => {
                setShowLoader(false)
                toastDisplay("Something went wrong", "error")
            });
    }, [refresh])

    async function deleteFile(fileName) {
        setShowLoader(true)
        axios.post(BASE_URL + "/delete-file", { fileName }).
            then(result => {
                setShowLoader(false)
                setRefresh(refresh + 1)
                toastDisplay("File removed successfully", "success")
            }).catch(err => {
                setShowLoader(false)
                toastDisplay("Something went wrong", "error")
            });
    }

    async function uploadFile() {
        if (selectedFile) {
            if (selectedFile.size <= 10000000) {
                setShowLoader(true)
                let formData = new FormData()
                formData.append("file", selectedFile)
                axios.post(BASE_URL + "/upload-file", formData, {headers: {
                    'Content-Type': 'multipart/form-data'
                  }}).
                    then(result => {
                        setShowLoader(false)
                        setRefresh(refresh + 1)
                        toastDisplay("File uploaded", "success")
                        setSelectedFile(null)
                    }).catch(err => {
                        setShowLoader(false)
                        toastDisplay("Something went wrong", "error")
                    });
            }
            else {
                toastDisplay("File size can't exceed more than 10 mb", "info")
            }
        }
        else {
            toastDisplay("Select file to upload", "info")
        }
    }

    const handleFileChange = (event) => {
        // console.log("eventtttttttttttttttttttttttttt", event.target.files[0])
        let supportedTypes = ["html", "pdf", "csv", 'application/vnd.openxmlformats']
        if (event.target?.files?.[0]?.type && supportedTypes.filter(i => {
            if(event.target.files[0].type.includes(i)){
                return i
            }
        })?.[0]) {
            setSelectedFile(event.target.files[0]);
        }
        else {
            toastDisplay("File format not supported", "error")
            setSelectedFile(null)
        }
    };

    //   const handleFileUpload = () => {
    //     if (selectedFile) {
    //       const fileItem = {
    //         name: selectedFile.name,
    //         size: selectedFile.size,
    //         type: selectedFile.type,
    //       };

    //       setFileList((prevFileList) => [...prevFileList, fileItem]);
    //       setSelectedFile(null);
    //     }
    //   };

    const handleFileDelete = (fileName) => {
        const updatedFileList = fileList.filter((file) => file.name !== fileName);
        setFileList(updatedFileList);
    };


    const downloadFile = (fileName) => {
        axios.post(BASE_URL + `/download-file`, { fileName }, { responseType: 'blob' })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toastDisplay("File downloaded", "success")
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
            toastDisplay("Something went wrong", "error")
          });
      };

    async function trainData() {
        setShowLoader(true)
        axios.post(BASE_URL + "/train-data",{}).
            then(result => {
                setShowLoader(false)
                toastDisplay("Model trained", "success")
            }).catch(err => {
                setShowLoader(false)
                toastDisplay("Something went wrong", "error")
            });
    }

    return (
        <>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
            {showLoader && (<div className="loading-overlay"><span><img className="" src={"assets/images/loader.gif"}
                alt="description" /></span></div>)}
            <NavBar activeComponent={"content"} />
            <div className="container">
                <h1 className='font-size-18 mt-5' >File Uploader</h1>

                <div className="form-group">
                    <input type="file" className="form-control-file" onChange={handleFileChange} />
                    <button className="btn btn-primary mt-2" onClick={uploadFile}>
                        Upload
                    </button>
                    <button className="btn btn-primary mt-2 mx-5" onClick={trainData}>
                        Train Data
                    </button>
                </div>

                {fileList.length > 0 && (
                    <div className="mt-4">
                        <div className='d-flex' >
                            <h2 className='font-size-18 mt-5'>File List</h2>
                        </div>
                        <ul className="list-group">
                            {fileList.map((file, index) => (
                                <li className="list-group-item d-flex" key={index}>
                                    {file.name} ({file.size} bytes)
                                    <div
                                        style={{ marginLeft: 'auto' }}>
                                        <button
                                            className="btn btn-primary btn-sm mx-3"
                                            onClick={() => downloadFile(file.name)}
                                        >
                                            Download
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm float-right"
                                            onClick={() => { deleteFile(file.name) }}
                                        >
                                            Delete
                                        </button>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default Content;