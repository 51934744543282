import React from "react";

const NavBar = ({activeComponent}) => {
    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light px-4">
            <div class="container-fluid">
                <div class="navbar-brand" onClick={()=>{window.location="/"}}><img className="brand-logo cursor" src="assets/images/tr_logo_updated.svg" /></div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li class="nav-item">
                            <div class={`nav-link cursor font-size-18 ${activeComponent==="content" && 'active'}`} aria-current="page" onClick={()=>{window.location="/"}}
                            >Content</div>
                        </li> */}
                        {/* <li class="nav-item">
                            <a class={`nav-link font-size-18 ${activeComponent==="chatbot" && 'active'}`} aria-current="page" href="content">Content</a>
                        </li> */}
                        {/* <li class="nav-item">
                            <a class={`nav-link font-size-18 ${activeComponent==="chatbot" && 'active'}`} aria-current="page" href="chatbot">ChatBot</a>
                        </li> */}
                        {/* <li class="nav-item">
                            <a class={`nav-link ${activeComponent==="contact" && 'active'}`} aria-current="page" href="contact">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${activeComponent==="recruit" && 'active'}`} aria-current="page" href="recruit-with-us">Onboarding Request</a>
                        </li> */}
                        {/* <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li> */}
                        {/* <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                        </li> */}
                    </ul>
                    {/* <form class="d-flex">
                            <button
                            onClick={(e)=>{e.preventDefault(); window.location="login"}}
                            class="btn btn-outline-success" type="submit">Member Login</button>
                    </form> */}
                </div>
            </div>
        </nav>
    )
}

export default NavBar